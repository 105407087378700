import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { Container, Col, Row, Button, ButtonGroup } from 'react-bootstrap';
import { Stack } from '@mui/material';
import { IoIosArrowBack } from "react-icons/io";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GoGraph } from "react-icons/go";

import moment from 'moment'
import { saveAs } from 'file-saver'

import MapReplay from './components/MapReplay';

import TimeSeriesPlot from 'Shared/Components/TimeSeriesPlot'
import DatePickerComponent from 'Shared/Components/DatePickerComponent'
import TimePickerComponent from 'Shared/Components/TimePickerComponent'
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { showError } from "Shared/Components/NotifyToast";
import { IconButtonComponent } from 'Shared/Components/Icon';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl } from "Shared/utils";

const SmartwatchDetail = () => {
  const routeParam = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { send } = useFetchData()

  const [temperature, setTemperature] = useState()
  const [heartRate, setHeartRate] = useState()
  const [bloodPressure, setBloodPressure] = useState()
  const [batteryLevel, setBatteryLevel] = useState()

  const [date, setDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [plotRange, setPlotRange] = useState({
    "start": 0,
    "end": 24
  })

  const [loading, setLoading] = useState(false)

  const mapRef = useRef(null);

  const loadData = async () => {
    const device_IMEI = routeParam.device_IMEI
    const start_dt = `${moment(date).format("YYYY-MM-DD")}T${moment(startTime).format("HH:mm:00")}`
    const end_dt = `${moment(date).format("YYYY-MM-DD")}T${moment(endTime).format("HH:mm:00")}`

    const getTemperature = async () => {
      const body = await send({
        url: `${apiUrl}/smartwatch/temperature`,
        params: {
          device_IMEI: device_IMEI,
          start_dt: start_dt,
          end_dt: end_dt
        },
        method: "GET",
        returnType: "json"
      })

      setTemperature(body.map((i) => ({
        time: new Date(i["datetime"]).getTime(),
        body_temperature: i["body_temperature"]
      })))
    }

    const getHeartRate = async () => {
      const body = await send({
        url: `${apiUrl}/smartwatch/heart_rate`,
        params: { device_IMEI: device_IMEI, start_dt: start_dt, end_dt: end_dt },
        method: "GET",
        returnType: "json"
      })

      setHeartRate(body.map((i) => ({
        time: new Date(i["datetime"]).getTime(),
        heartbeat: i["heartbeat"]
      })))
    }

    const getBloodPressure = async () => {
      const body = await send({
        url: `${apiUrl}/smartwatch/blood_pressure`,
        params: { device_IMEI: device_IMEI, start_dt: start_dt, end_dt: end_dt },
        method: "GET",
        returnType: "json"
      })

      setBloodPressure(body.map((i) => ({
        time: new Date(i["datetime"]).getTime(),
        diastolic: i["diastolic"],
        shrink: i["shrink"]
      })))
    }

    const getBatteryLevel = async () => {
      const body = await send({
        url: `${apiUrl}/smartwatch/battery_level`,
        params: { device_IMEI: device_IMEI, start_dt: start_dt, end_dt: end_dt },
        method: "GET",
        returnType: "json"
      })

      setBatteryLevel(body.map((i) => ({
        time: new Date(i["datetime"]).getTime(),
        // signal: i["signal"],
        battery: i["battery"]
      })))
    }

    const getLocation = async () => {
      const body = await send({
        url: `${apiUrl}/smartwatch/location`,
        params: { device_IMEI: device_IMEI, start_dt: start_dt, end_dt: end_dt },
        method: "GET",
        returnType: "json"
      })

      mapRef.current.updateData(body["points"], body["lines"])
    }

    setLoading(true)

    await Promise.all([
      getTemperature(),
      getHeartRate(),
      getBloodPressure(),
      getBatteryLevel(),
      getLocation()
    ])

    setLoading(false)
  }

  const updateDate = () => {
    if (date && startTime && endTime) {
      loadData()

      const start = parseInt(moment(startTime).startOf('hour').format('HH'))

      var end = moment(endTime)
      if (end.format("mm") !== "00") { end = end.add(1, "hours").startOf('hours') }
      end = end.format('HH')

      setPlotRange({
        "start": start,
        "end": end != "00" ? parseInt(end) : 24
      })
    }
  }

  const exportReport = async (item) => {
    if (!startTime || !endTime) {
      showError("No time period selected")
      return
    }

    setLoading(true)

    const device_IMEI = routeParam.device_IMEI
    const start_dt = `${moment(date).format("YYYY-MM-DD")}T${moment(startTime).format("HH:mm:00")}`
    const end_dt = `${moment(date).format("YYYY-MM-DD")}T${moment(endTime).format("HH:mm:00")}`

    const res = await fetch(`https://smartwatch.simplesymmetry-tech.com/report?field=${item}&device_IMEI=${device_IMEI}&start_dt=${start_dt}&end_dt=${end_dt}`)
    const blob = await res.blob()

    if (blob != null) {
      saveAs(blob, `${device_IMEI}-${item}.xlsx`)
    }

    setLoading(false)
  }

  const exportGraph = async (item) => {
    if (!startTime || !endTime) {
      showError("No time period selected")
      return
    }

    setLoading(true)

    const device_IMEI = routeParam.device_IMEI
    const start_dt = `${moment(date).format("YYYY-MM-DD")}T${moment(startTime).format("HH:mm:00")}`
    const end_dt = `${moment(date).format("YYYY-MM-DD")}T${moment(endTime).format("HH:mm:00")}`

    const res = await fetch(`https://smartwatch.simplesymmetry-tech.com/graph?field=${item}&device_IMEI=${device_IMEI}&start_dt=${start_dt}&end_dt=${end_dt}`)
    const blob = await res.blob()

    if (blob != null) {
      saveAs(blob, `${device_IMEI}-${item}.png`)
    }

    setLoading(false)
  }

  return (<>
    <Container fluid className="mainContainer">
      <Row style={{ marinTop: "10px", marginBottom: "10px" }}>
        <Col xs={12}>
         <Button variant="link" style={{ textDecoration: "none" }} onClick={() => navigate("./..")}>
            <IoIosArrowBack /> Back
          </Button>
        </Col>

        <Col xs={12} lg={5}>
          <DatePickerComponent date={date} max={new Date()} type="day" onChange={(d) => setDate(d)}/>
        </Col>

        <Col xs={12} lg={5} className="mt-3 mb-3 mt-lg-0 mb-lg-0">
          <Stack direction="row"  style={{ height: "100%" }}>
            <div style={{ width: "47%" }}><TimePickerComponent value={startTime} onChange={(d) => setStartTime(d)}/></div>
            <div style={{ height: "100%", width: "6%", display: "flex", justifyContent: "center", alignItems: "center" }}>~</div>
            <div style={{ width: "47%" }}><TimePickerComponent value={endTime} onChange={(d) => setEndTime(d)}/></div>
          </Stack>
        </Col>

        <Col xs={12} lg={2} onClick={() => updateDate()}>
          <ButtonGroup style={{ width: "100%" }}>
            <Button>{t("general_search")}</Button>
          </ButtonGroup>
        </Col>
      </Row>

      <Row style={{ flexGrow: 1, overflow: "auto" }}>
        <Col xs={12} style={{ height: "100%", padding: "10px", overflow: "auto" }}>
          <Stack spacing={3}>
            <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                {t("smartwatch_temperature")}
              </div>

              <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Stack direction="row" spacing={2}>
                  <IconButtonComponent size="small" title="Report">
                    <HiOutlineDocumentReport onClick={() => exportReport("temperature")}/>
                  </IconButtonComponent>
                  
                  <IconButtonComponent size="small" title="Graph">
                    <GoGraph onClick={() => exportGraph("temperature")}/>
                  </IconButtonComponent>
                </Stack>
              </div>

              <div style={{ flexGrow: 1, margin: "5px" }} >
                <TimeSeriesPlot
                  data={temperature ?? []}
                  items={[
                    { "axis": "left", "dataKey":"body_temperature", "name": t("smartwatch_temperature"), "stroke": "#8884d8" },
                  ]}
                  day={moment(date).format("YYYY-MM-DD")}
                  hour={[plotRange["start"], plotRange["end"]]}
                  yLeft={{ "range": [34, 40], "label": "" }}
                />
              </div>
            </div>

            <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                {t("smartwatch_heartRate")}
              </div>

              <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Stack direction="row" spacing={2}>
                  <IconButtonComponent size="small" title="Report">
                    <HiOutlineDocumentReport onClick={() => exportReport("heart_rate")}/>
                  </IconButtonComponent>
                      
                  <IconButtonComponent size="small" title="Graph">
                    <GoGraph onClick={() => exportGraph("heart_rate")}/>
                  </IconButtonComponent>
                </Stack>
              </div>

              <div style={{ flexGrow: 1, margin: "5px" }} >
                <TimeSeriesPlot
                  data={heartRate ?? []}
                  items={[
                    { "axis": "left", "dataKey":"heartbeat", "name": t("smartwatch_heartRate"), "stroke": "#8884d8" },
                  ]}
                  day={moment(date).format("YYYY-MM-DD")}
                  hour={[plotRange["start"], plotRange["end"]]}
                  yLeft={{ "range": [60, 150], "label": "" }}
                />
              </div>
            </div>

            <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                {t("smartwatch_bloodPressure")}
              </div>

              <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Stack direction="row" spacing={2}>
                  <IconButtonComponent size="small" title="Report">
                    <HiOutlineDocumentReport onClick={() => exportReport("blood_pressure")}/>
                  </IconButtonComponent>
                      
                  <IconButtonComponent size="small" title="Graph">
                    <GoGraph onClick={() => exportGraph("blood_pressure")}/>
                  </IconButtonComponent>
                </Stack>
              </div>

              <div style={{ flexGrow: 1, margin: "5px" }} >
                <TimeSeriesPlot
                  data={bloodPressure ?? []}
                  items={[
                    { "axis": "left", "dataKey":"diastolic", "name": t("smartwatch_bloodPressureDiastolic"), "stroke": "#8884d8" },
                    { "axis": "left", "dataKey":"shrink", "name": t("smartwatch_bloodPressureShrink"), "stroke": "#4884d8" },
                  ]}
                  day={moment(date).format("YYYY-MM-DD")}
                  hour={[plotRange["start"], plotRange["end"]]}
                  yLeft={{ "range": [50, 140], "label": "" }}
                />
              </div>
            </div>

            <div style={{ height: "300px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                {t("smartwatch_battery")}
              </div>

              <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                <Stack direction="row" spacing={2}>
                  <IconButtonComponent size="small" title="Report">
                    <HiOutlineDocumentReport onClick={() => exportReport("battery_level")}/>
                  </IconButtonComponent>
                        
                  <IconButtonComponent size="small" title="Graph">
                    <GoGraph onClick={() => exportGraph("battery_level")}/>
                  </IconButtonComponent>
                </Stack>
              </div>

              <div style={{ flexGrow: 1, margin: "5px" }} >
                <TimeSeriesPlot
                  data={batteryLevel ?? []}
                  items={[
                    // { "axis": "left", "dataKey":"signal", "name": t("smartwatch_signal"), "stroke": "#8884d8" },
                    { "axis": "left", "dataKey":"battery", "name": t("smartwatch_battery"), "stroke": "#4884d8" },
                  ]}
                  day={moment(date).format("YYYY-MM-DD")}
                  hour={[plotRange["start"], plotRange["end"]]}
                  yLeft={{ "range": [0, 100], "label": "" }}
                />
              </div>
            </div>

            <div style={{ height: "500px", border: "1px solid black", borderRadius: "8px", display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", textAlign: "center" }}>
                {t("smartwatch_positioning")}
              </div>

              <div style={{ flexGrow: 1, margin: "5px" }} >
                <MapReplay ref={mapRef} start_dt={startTime} end_dt={endTime} exportReport={exportReport}/>
              </div>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} text={t("general_loading")}/>
  </>)
}

export default SmartwatchDetail
